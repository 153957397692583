import "bootstrap";
import {FloatLabels} from "@keodesign/float-labels";
//import "core-js/actual/index.js";
// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.

export function SubscribeAccordian(){
	[...document.querySelectorAll("[href='#subscribeFormAccordian'][data-bs-toggle='collapse']")].forEach(el => {
		el.addEventListener("click", () => {document.getElementById("subscribeFormAccordian").scrollIntoView();});
	});

	[...document.querySelectorAll("[href='#subscribeFormAccordian']:not([data-bs-toggle='collapse'])")].forEach(el => {
		el.addEventListener("click", () => {
			const target=document.getElementById("subscribeFormAccordian");
			let collapseInstance = bootstrap.Collapse.getInstance(target);
			if (!collapseInstance) {
				collapseInstance = new bootstrap.Collapse(target);
			}
			collapseInstance.show();
			document.getElementById("subscribeFormAccordian").scrollIntoView();
		});
	});
}

// fund Target Market customer link click interceptor
var popupContinue = false;
export function PopupLinks() {
	[...document.querySelectorAll("a.popup-sif,a.popup-sbf")].forEach(el => {
		el.addEventListener("click", (e) => {		
			e.preventDefault();
			if (popupContinue){
				popupContinue = false;
				window.open(el.href);
			} else {
				smallcoPopup(el);
			}
		});
	});


	// Wholesale link click interceptor
	[...document.querySelectorAll("a.wholesale")].forEach(el => {
		el.addEventListener("click", e => {
			e.preventDefault();
			createDialog("The following information is only available to Wholesale Investors. By clicking the agree button below you are confirming that you are a Wholesale Investor as defined by the Australian Corporations Act.",
				function(){ window.location.href=el.href;},
				function(){
					//window.location.href="/"; - close w/out redirecting to home
				}, "I agree", "I disagree"
			);
		});
	});
}

export function smallcoPopup(target){
	var t_class = target.classList[0];
	var my_popup = document.querySelector("div."+t_class);
	const myModal = new bootstrap.Modal(my_popup, {});
	myModal.show();

	var continueBtn = my_popup.querySelector(".continue");
	
	continueBtn.addEventListener("click", () =>{
		// close modal
		myModal.hide();
		myModal.dispose();
		popupContinue = true;
		// trigger the document open this time		
		target.dispatchEvent(new Event("click"));		
	});
}
function generateElement(html) {
	const template = document.createElement("template");
	template.innerHTML = html.trim();
	return template.content.children[0];
}
  
export function createDialog(question, yesaction, noaction, yesbutton, nobutton){
	var modalEl = document.querySelector("#modal_question");
	const myModal = new bootstrap.Modal(modalEl, {});
	var modalBody = modalEl.querySelector(".modal-body");
	var modalFooter = modalEl.querySelector(".modal-footer");
	modalBody.innerHTML = "";
	modalFooter.innerHTML ="";
	var yesbuttontext = yesbutton || "Yes";
	var nobuttontext = nobutton || "No";
	modalBody.insertAdjacentElement("beforeend", generateElement("<p>"+question+"</p>"));
	var closeDialog = () => {
		myModal.hide();
		myModal.dispose();
	};
	if (yesaction && noaction) {
		var yesbuttonel = generateElement("<button class='btn btn-primary'>" + yesbuttontext + "</button>");
		var nobuttonel = generateElement("<button class='btn btn-secondary'>" + nobuttontext + "</button>");
		modalFooter.insertAdjacentElement("beforeend", yesbuttonel);
		modalFooter.insertAdjacentElement("beforeend", nobuttonel);
		yesbuttonel.addEventListener("click", () =>{
			closeDialog();
			yesaction();
		});
		nobuttonel.addEventListener("click", () => {
			closeDialog();
			noaction();
		});
	} else {
		var okbutton = generateElement("<button class='btn btn-primary'>OK</button>");
		modalFooter.insertAdjacentElement("beforeend", okbutton);
		okbutton.addEventListener("click", function(){
			closeDialog();
		});
	}
	myModal.show();
}

export function StickyNav() {
	var stickyNav = document.querySelector(".sticky-nav");
	if (!stickyNav) {
		return;
	}
	var navParent = stickyNav.parentElement;
	var navHeight = stickyNav.offsetHeight;
	window.addEventListener("resize", function() {
		navHeight = stickyNav.offsetHeight;
		if (stickyNav.classList.contains("fixed-top")) {
			navParent.style.paddingTop = navHeight + "px";
		}
	});
	window.addEventListener("scroll", function() {
		if (document.scrollingElement.scrollTop>0) {
			stickyNav.classList.add("fixed-top");
			navParent.style.paddingTop = navHeight + "px";
		} else {
			stickyNav.classList.remove("fixed-top");
			navParent.style.paddingTop = "0";
		}
	});
}

export function init(){
	new FloatLabels(".js-float-wrap");
	SubscribeAccordian();
	PopupLinks();
	StickyNav();
}

document.addEventListener("DOMContentLoaded", init);
  
// Check if the document's DOM content is already loaded
if (document.readyState === "interactive" || document.readyState === "complete") {
	// Run the function immediately
	init();
}